<template>
  <Loading
    v-if="!data.mounted || !data.supplierCategories || !data.supplierBlocks"
    size="small"
  />
  <div v-else :class="['suppliers__main', { wrapper__lg: grid.desktop }]">
    <div class="suppliers__nav--mobile">
      <ul>
        <li
          v-for="(category, idx) in data.supplierCategories"
          :key="idx"
          @click="
            data.selectedCategory === category.title.split(' ')[0].toLowerCase()
              ? (data.selectedCategory = '')
              : (data.selectedCategory = category.title
                  .split(' ')[0]
                  .toLowerCase())
          "
          :class="['suppliers__category']"
        >
          <div
            :class="[
              'suppliers__category__title',
              {
                'suppliers__category__title--active':
                  data.selectedCategory ===
                  category.title.split(' ')[0].toLowerCase(),
              },
            ]"
          >
            <div>
              <div
                v-if="category.icon"
                v-html="category.icon"
                class="supplier__category__icon"
              ></div>
              <p class="accent">{{ category.title }}</p>
            </div>
          </div>
          <transition-expand>
            <ul
              v-if="
                data.selectedCategory ===
                category.title.split(' ')[0].toLowerCase()
              "
            >
              <li
                v-for="(supplier, idx) in filteredCategory"
                :key="idx"
                class="suppliers__block"
              >
                <div>
                  <div>
                    <img
                      v-if="supplier.logo"
                      @click="supplierRedirect(supplier.link)"
                      :src="supplier.logo"
                      :alt="supplier.title"
                      :class="{ 'cursor-pointer': supplier.link }"
                    />
                    <div v-else class="suppliers__fallback">
                      <img src="@/images/placeholder.png" alt="AHM" />
                    </div>
                  </div>
                  <p class="accent">{{ supplier.title }}</p>
                </div>
                <div
                  v-if="supplier.description"
                  v-html="supplier.description"
                ></div>
              </li>
            </ul>
          </transition-expand>
        </li>
        <li
          @click="
            data.selectedCategory === 'all'
              ? (data.selectedCategory = '')
              : (data.selectedCategory = 'all')
          "
          :class="['suppliers__category']"
        >
          <div
            :class="[
              'suppliers__category__title',
              {
                'suppliers__category__title--active':
                  data.selectedCategory === 'all',
              },
            ]"
          >
            <div>
              <div class="supplier__category__icon">
                <icon-brands />
              </div>
              <p class="accent">All</p>
            </div>
          </div>

          <transition-expand>
            <ul v-if="data.selectedCategory === 'all'">
              <li
                v-for="(supplier, idx) in data.supplierBlocks"
                :key="idx"
                class="suppliers__block"
              >
                <div>
                  <div>
                    <img
                      v-if="supplier.logo"
                      @click="supplierRedirect(supplier.link)"
                      :src="supplier.logo"
                      :alt="supplier.title"
                      :class="{ 'cursor-pointer': supplier.link }"
                    />
                    <div v-else class="suppliers__fallback">
                      <img src="@/images/placeholder.png" alt="AHM" />
                    </div>
                  </div>
                  <p class="accent">{{ supplier.title }}</p>
                </div>
                <div
                  v-if="supplier.description"
                  v-html="supplier.description"
                ></div>
              </li>
            </ul>
          </transition-expand>
        </li>
      </ul>
    </div>
    <div class="suppliers__nav--desktop">
      <ul>
        <li
          v-for="(category, idx) in data.supplierCategories"
          :key="idx"
          @click="
            data.selectedCategory = category.title.split(' ')[0].toLowerCase()
          "
          class="suppliers__category"
        >
          <div
            :class="[
              'suppliers__category__title',
              {
                'suppliers__category__title--active':
                  data.selectedCategory ===
                  category.title.split(' ')[0].toLowerCase(),
              },
            ]"
          >
            <div>
              <div
                v-if="category.icon"
                v-html="category.icon"
                class="supplier__category__icon"
              ></div>
              <p class="accent">{{ category.title }}</p>
            </div>
          </div>
        </li>
        <li @click="data.selectedCategory = 'all'" class="suppliers__category">
          <div
            :class="[
              'suppliers__category__title',
              {
                'suppliers__category__title--active':
                  data.selectedCategory === 'all',
              },
            ]"
          >
            <div>
              <div class="supplier__category__icon">
                <icon-brands />
              </div>
              <p class="accent">All Brands</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="suppliers__blocks--desktop hidden desktop:block">
      <div
        v-for="(supplier, idx) in data.selectedCategory !== 'all'
          ? filteredCategory
          : data.supplierBlocks"
        :key="idx"
        class="suppliers__block"
      >
        <div v-if="supplier.logo" @click="supplierRedirect(supplier.link)" :class="['suppliers__logo', { 'cursor-pointer': supplier.link }]">
          <img :src="supplier.logo" :alt="supplier.title" />
        </div>
        <div v-else class="suppliers__fallback">
          <img src="@/images/placeholder.png" alt="AHM" />
        </div>
        <div class="suppliers__content">
          <p class="accent">{{ supplier.title }}</p>
          <div v-if="supplier.description" v-html="supplier.description"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, onMounted, onBeforeMount, computed } from 'vue';
import { useGrid } from 'vue-screen';
import Loading from './Loading.vue';
import TransitionExpand from './transitions/TransitionExpand.vue';
import IconBrands from './icons/IconBrands.vue';
import { windowRedirect } from '../../utils/helpers';

const props = defineProps({
  supplier_categories: {
    type: Array,
    required: true,
  },
  supplier_blocks: {
    type: Array,
    required: true,
  },
});

const data = reactive({
  mounted: false,
  supplierCategories: null,
  supplierBlocks: null,
  selectedCategory: 'accessories',
});

const grid = useGrid({
  mobile: '416px',
  tablet: '640px',
  'tablet-lg': '1024px',
  laptop: '1152px',
  desktop: '1460px',
  ultrawide: '2000px',
});

const filteredCategory = computed(() => {
  return data.supplierBlocks.filter((supplier) => {
    return (
      supplier.category
        .toLowerCase()
        .indexOf(data.selectedCategory.toLowerCase()) != -1
    );
  });
});

const urlParamsCheck = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString).get('tab');
  data.selectedCategory = urlParams ? urlParams : 'accessories';
  return;
};

const supplierRedirect = (link) => {
  if (link) {
    windowRedirect(link)
  }
  return;
};

onBeforeMount(() => {
  data.supplierCategories = props.supplier_categories;
  data.supplierBlocks = props.supplier_blocks;
  urlParamsCheck();
});

onMounted(() => {
  data.mounted = true;
});
</script>
