<template>
  <div>
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.9377 10.3369V18.5897C18.9377 19.3266 18.3377 19.9266 17.5995 19.9266H9.33826C8.60057 19.9266 8 19.3266 8 18.5897V10.3369C8 9.59996 8.60057 9 9.33826 9H17.5995C18.3377 9 18.9377 9.59996 18.9377 10.3369Z"
        fill="#303030"
      />
      <path
        d="M33.9983 10.3648V18.6176C33.9983 19.3545 33.3977 19.9545 32.66 19.9545H24.3988C23.6606 19.9545 23.0605 19.3545 23.0605 18.6176V10.3648C23.0605 9.62733 23.6606 9.02793 24.3988 9.02793H32.66C33.3977 9.02793 33.9983 9.62762 33.9983 10.3648Z"
        fill="#303030"
      />
      <path
        d="M33.9983 25.4103V33.6631C33.9983 34.4 33.3977 35 32.66 35H24.3988C23.6606 35 23.0605 34.4 23.0605 33.6631V25.4103C23.0605 24.6734 23.6606 24.0734 24.3988 24.0734H32.66C33.3977 24.0732 33.9983 24.6731 33.9983 25.4103Z"
        fill="#303030"
      />
      <path
        d="M18.9377 25.3833V33.6361C18.9377 34.3736 18.3377 34.973 17.5995 34.973H9.33826C8.60057 34.973 8 34.3735 8 33.6361V25.3833C8 24.6463 8.60057 24.0464 9.33826 24.0464H17.5995C18.3377 24.0461 18.9377 24.6461 18.9377 25.3833Z"
        fill="#303030"
      />
    </svg>
  </div>
</template>
